import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES, WRAPPER_STYLES } from "../../../AppConst";
import { SubTitle } from '../common/SubTitle';
import { CatchCopy } from "../common/CatchCopy";
import { SpWrapper } from "../../common/SpWrapper";
import { FadeIn } from '../../common/FadeIn'
import { FadeInOptions } from '../common//FadeInOptions'

/**
 * Element & Styles
 */
const Node = styled.div`
  width: 100%;
  margin: auto;
  background: #FFF;
  padding: 120px 0;
  box-sizing: border-box;

  @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    padding: 64px 0;
  }
`

const Wrapper = styled.div`
  ${WRAPPER_STYLES}
  max-width: 1080px;
  text-align: center;
`

const CatchCopyWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`

const VisionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  width: 100%;
  max-width: 1080px;
  margin: 64px auto 0;
  padding: 48px 0px;
  box-sizing: border-box;
  background: #F4F4F4;

  @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    margin: 32px auto 0;
    padding: 16px;
  }
`

const VisionTitle = styled.h3`
  margin: 0;
  padding: 0;

  color: #222;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 200% */

  @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
  }
`
const VisionMessage = styled.p`
  margin: 0;

  color: #222;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 64px; /* 200% */
  letter-spacing: 1.6px;
  text-transform: capitalize;

  @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    font-size: 20px;
    line-height: 40px; /* 200% */
    letter-spacing: 1px;
  }
`

export const Vision = () => {
  return (
    <Node>
      <Wrapper>
        <FadeIn {...FadeInOptions}>
          <SpWrapper>
            <SubTitle subTitle="VISION" text="目指す方向性" />
            <CatchCopyWrapper>
              <CatchCopy text="人と組織の成⻑インフラを創造する" />
            </CatchCopyWrapper>
            <VisionWrapper>
              <VisionTitle>中期ビジョン</VisionTitle>
              <VisionMessage>
                圧倒的に突き抜けた独自のサービスを展開し<br />多くの人に期待される企業になる
              </VisionMessage>
            </VisionWrapper>
          </SpWrapper>
        </FadeIn>
      </Wrapper>
    </Node>
  )
}

export default Vision
