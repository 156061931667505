import React from "react"
import { graphql } from "gatsby";
import { Philosophy } from "../components/philosophy"

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `PHILOSOPHY`, path: `philosophy` },
]

export default function PhilosophyPage({ data }) {
  const { ogpImage, missionBg } = data;

  return (
    <Philosophy breadcrumbsData={breadcrumbsData} ogpImage={ogpImage} missionBg={missionBg} />
  )
}

export const query = graphql`
  query {
    ogpImage: file(relativePath: { eq: "images/philosophy_ogp.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    missionBg: file(relativePath: { eq: "images/mission_bg.png"}) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
