import React from "react"
import styled from "styled-components"
import { SectionTitle } from '../../common/SectionTitle'
import { SubTitle } from '../common/SubTitle';
import { RESPONSIVE_STYLES } from "../../../AppConst";
import missionImg from '../../../images/mission.svg'
import { SpWrapper } from "../../common/SpWrapper";

/**
 * Element & Styles
 */
const Wrapper = styled.div`
  width: 100%;
`

const MissionWrapper = styled.div<{missionBg: any}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 96px auto 0;
  padding: 120px 0;
  background-image: url('${props => props.missionBg.childImageSharp.fluid.src}'), linear-gradient(lightgray, lightgray);
  background-position: 0% 0%, 0% 0%;
  background-size: 100px 100px, auto;
  background-repeat: repeat, no-repeat;
  background-color: rgba(34, 34, 34, 0.05);

  @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    margin: 48px auto 0 auto;
    padding: 64px 0;
  }
`

const StaticImg = styled.img`
  width: 100%;
  max-width: 1080px;
`

const Message = styled.p`
  margin: 42px auto 0;
  padding: 0;

  color: #222;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 200% */
  letter-spacing: 1.2px;
  text-transform: capitalize;
  text-align: center;

  @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    margin: 24px auto 0;
    font-size: 16px;
    line-height: 32px; /* 200% */
    letter-spacing: 0.8px;
  }
`

type Props = {
  missionBg: any
}
export const Mission: React.VFC<Props> = (props) => {
  return (
    <Wrapper>
      <SectionTitle title='PHILOSOPHY' subTitle='企業理念' />
      <MissionWrapper missionBg={props.missionBg}>
        <SpWrapper>
          <SubTitle subTitle={"mission"} text={"存在意義"} />
          <StaticImg src={missionImg} alt="0→1 INNOVATION（ゼロイチイノベーション）" />
          <Message>新たな概念をもって創造的破壊を巻き起こし、後世につなぐ</Message>
        </SpWrapper>
      </MissionWrapper>
    </Wrapper>
  )
}

export default Mission
