import React, { useRef, useEffect, useState } from "react"
import styled from "styled-components";

const Wrapper = styled.div<{ display: string, width: string, delay: number }>`
  width: ${props => props.width};
  display: ${props => props.display};
  &[data-active='false'] {
    opacity: 0;
    transition: 1s;
    transform: translateY(100px);
  }
  &[data-active='true'] {
    opacity: 1.0;
    transition: 1s;
    transform: translateX(0);
    transition-delay: ${props => props.delay}ms;
  }
`

export const FadeIn = ({display = 'block', width = "100%", rootMargin = '0px', threshold = 0, delay = 0, children}) => {

  const ref = useRef(null);
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const options = {
      rootMargin: rootMargin,
      threshold: threshold
    };

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting === true) {
        setIsShown(true);
      }
    }, options);

    observer.observe(ref.current);
  }, []);

  return <Wrapper ref={ref} data-active={isShown} display={display} width={width} delay={delay}>{ isShown ? children : null}</Wrapper>
}
