import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES, WRAPPER_STYLES } from "../../../AppConst";
import { SubTitle } from '../common/SubTitle';
import { SpWrapper } from "../../common/SpWrapper";
import { FadeIn } from '../../common/FadeIn'
import { FadeInOptions } from '../common//FadeInOptions'

/**
 * Element & Styles
 */
const Node = styled.div`
  width: 100%;
  margin: auto;
  background: #FFF;
  padding: 120px 0;
  box-sizing: border-box;

  @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    padding: 64px 0;
  }
`

const Wrapper = styled.div`
  ${WRAPPER_STYLES}
  max-width: 1080px;
  text-align: center;
`

const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;

  @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    gap: 24px;
  }
`

const Name = styled.p`
  display: flex;
  gap: 16px;

  width: 100%;
  max-width: calc(50% - 20px);
  margin: 0;
  padding: 16px 24px;
  box-sizing: border-box;

  background: #F4F4F4;

  @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    gap: 8px;
    max-width: calc(50% - 12px);
    padding: 8px 16px;
  }
  @media screen and (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px){
    max-width: 100%;
  }

  &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8) {
    max-width: 100%;
  }

  & > span {
    display: flex;
    align-items: center;

    color: #222;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 200% */
    letter-spacing: 1px;
    text-align: left;

    &:nth-child(1) {
      min-width: 48px;
      font-family: Righteous;
      color: #61B1C1;

      @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
        min-width: auto;
      }
    }

    &:nth-child(2) {
      @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
        font-size: 14px;
        line-height: 28px; /* 200% */
        letter-spacing: 0.7px;
      }
    }
  }
`

const names = [
  "モチベーションに頼らない。",
  "考えながら行動する。",
  "アウトプットすることで学ぶ。",
  "手段を目的にしない。",
  "「相手から何を得るのか」ではなく、「相手に何を与えられるのか」という視点で取り組む。",
  "やりたいことのために、やるべきこともやる。",
  "迷ったときは、ワクワクするほうを選ぶ。",
  "「伝える」ではなく、「伝わる」コミュニケーションを意識する。",
  "視線を未来に向け、肯定的に物事を見る。",
  "積極的に新しい体験をする。",
]

export const Action = () => {
  return (
    <Node>
      <Wrapper>
        <FadeIn {...FadeInOptions}>
          <SpWrapper>
            <SubTitle subTitle="ACTIONS" text="挑戦と創造を支える行動基準" />
            <FadeIn {...FadeInOptions}>
              <Actions>
                {
                  names.map((n, i) => {
                    const index = i + 1;
                    return (
                      <Name><span>{index < 10 ? '0' + index : index}.</span><span>{n}</span></Name>
                    )
                  })
                }
              </Actions>
            </FadeIn>
          </SpWrapper>
        </FadeIn>
      </Wrapper>
    </Node>
  )
}

export default Action
