import React from "react"
import Layout from "../../template/Layout"
import styled from "styled-components"
import Head from "../../head";
import { TABLET_STYLES, WRAPPER_STYLES } from "../../AppConst"
import { Mission } from "./mission"
import { Value } from "./value"
import { Vision } from "./vision"
import { Action } from "./action"
import { Message } from "./message"
import { ogpUrl } from "../../utlis/ImageUtil"

import {
  Props as BreadcrumbsData,
} from "../common/Breadcrumbs"

/**
 * Component
 */

type Props = {
  breadcrumbsData: BreadcrumbsData["values"]
  ogpImage: any
  missionBg: any
}

export const Philosophy: React.VFC<Props> = ({
  breadcrumbsData,
  ogpImage,
  missionBg,
}) => {
  return (
    <Layout sidebar={false} breadcrumbsData={breadcrumbsData}>
      <Head
        title="企業理念 | ネクストライブ株式会社"
        description="ネクストライブ株式会社の企業理念ページです。"
        img={ogpUrl(process.env.NODE_ENV, ogpImage.childImageSharp.fluid)}
      />
        <Mission missionBg={missionBg} />
        <Vision />
        <Value />
        <Action />
        <Message />
    </Layout>
  )
}
