import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES, WRAPPER_STYLES } from "../../../AppConst";
import { SubTitle } from '../common/SubTitle';
import { SpWrapper } from "../../common/SpWrapper"
import { CatchCopy } from "../common/CatchCopy";
import { FadeIn } from '../../common/FadeIn'
import { FadeInOptions } from '../common//FadeInOptions'

/**
 * Element & Styles
 */
const Node = styled.div`
  width: 100%;
  padding: 120px 0;
  background-color: #222;

  @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    padding: 64px 0;
  }
`

const Wrapper = styled.div`
  ${WRAPPER_STYLES}
  max-width: 1080px;
  text-align: center;
`

const CatchCopyWrapper = styled.div`
  width: 100%;
  margin: 64px auto;

  @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    margin: 32px auto;
  }
`

const Heart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;

  & > span {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0 auto;
    color: #7A7A7A;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.8px;

    @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.7px;
    }

    &:before, &:after {
      content: '';
      display: block;
      width: 24px;
      height: 1.5px;
      background: #7A7A7A;
    }
  }

  & > p {
    margin: 0;

    color: #7A7A7A;

    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 1.2px;
    text-transform: capitalize;

    @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 1px;
    }
  }
`

const EvaluationCriteriaWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;

  @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    gap: 24px;
  }

  & > h3 {
    position: relative;
    margin: 0 auto;
    padding: 0;

    width: 100%;

    color: #FFF;
    text-align: center;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 200% */
    letter-spacing: 1px;

    @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      font-size: 16px;
      line-height: 32px; /* 200% */
      letter-spacing: 0.8px;
    }

    &:after {
      content: '';
      display: block;
      margin: auto;
      width: 99px;
      border-bottom: 2px solid #FFF;
    }
  }
`

const EvaluationCriteria = styled.div`
  display: flex;
  gap: 16px;
  width: calc(50% - 32px);

  @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    gap: 8px;
    width: calc(50% - 20px);
  }

  @media screen and (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px){
    width: 100%;
  }

  & > span {
    display: block;

    margin: 0;
    padding: 16px 0;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 1px;
    color: #7A7A7A;

    @media screen and (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      padding: 8px 0;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.8px;
    }

    &:nth-child(1) {
      flex: 1;
      background: #393939;
    }
    &:nth-child(3) {
      flex: 1;
      background: #fff;
      color: #222;
    }
  }
`

const values = [
  ["与えられる", "与える"],
  ["傍観する", "自ら動く"],
  ["利益", "信頼"],
  ["質", "スピード"],
  ["仕事", "家族"],
  ["安定", "変革"],
  ["損得", "おもしろいこと"],
  ["個人プレー", "チームプレー"],
]

export const Value = () => {
  return (
    <Node>
      <Wrapper>
        <FadeIn {...FadeInOptions}>
          <SpWrapper>
            <SubTitle subTitle="VALUES" text="挑戦と創造を支える価値観" color="#FFF" />
            <CatchCopyWrapper>
              <CatchCopy text="心ある仕事をする" color="#FFF" />
              <Heart>
                <span>「心ある」とは？</span>
                <p>心配り、思いやり、人間味</p>
              </Heart>
            </CatchCopyWrapper>
            <FadeIn {...FadeInOptions}>
              <EvaluationCriteriaWrapper>
                <h3>判断基準</h3>
                {
                  values.map((v) => {
                    return (
                      <EvaluationCriteria>
                        <span>{v[0]}</span>
                        <span>より</span>
                        <span>{v[1]}</span>
                      </EvaluationCriteria>
                    )
                  })
                }
              </EvaluationCriteriaWrapper>
            </FadeIn>
          </SpWrapper>
        </FadeIn>
      </Wrapper>
    </Node>
  )
}

export default Value
