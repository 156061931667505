import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
  max-width: 1080px;

  margin: 0 auto 64px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${props => props.color};

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 8px;
    margin: 0 auto 32px;
    padding-bottom: 8px;
  }
`

const Title = styled.h2`
  margin: 0;
  padding: 0;

  color: ${props => props.color};
  font-family: Righteous;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 3.2px;
  text-transform: uppercase;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 2.4px;
  }
`

const Text = styled.p`
  margin: 0;
  padding: 0;

  color: ${props => props.color};
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 200% */
`

type Props = {
  subTitle: string,
  text: string
} & {
  color?: string,
}

export const SubTitle: React.VFC<Props> = (props) => {
  return (
    <Wrapper color={props.color}>
      <Title color={props.color}>{props.subTitle}</Title>
      <Text color={props.color}>{props.text}</Text>
    </Wrapper>
  )
}

SubTitle.defaultProps = {
  color: '#222',
}
