import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES, WRAPPER_STYLES } from "../../../AppConst";
import { SubTitle } from '../common/SubTitle';
import { FadeIn } from '../../common/FadeIn'
import { FadeInOptions } from '../common//FadeInOptions'
import { SpWrapper } from "../../common/SpWrapper";

/**
 * Element & Styles
 */
const Node = styled.div`
  width: 100%;
  margin: auto;
  margin-bottom: 5px;
  background: #222;
`

const Wrapper = styled.div`
  ${WRAPPER_STYLES}
  width: 100%;
  max-width: 1080px;
  padding: 120px 0;
  box-sizing: border-box;
  text-align: center;

  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding: 64px 0;
  }
`

const CopyTitle = styled.h3`
  margin: 0 auto 32px;

  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: 2.4px;
  text-transform: capitalize;
  text-align: left;

  color: #77BDCA;

  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 1.2px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4rem;
`
const Text = styled.p`
  margin: 0;
  color: #FFF;

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 200% */
  letter-spacing: 1px;
  text-align: left;

  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 16px;
    line-height: 32px; /* 200% */
    letter-spacing: 0.8px;
  }
`

const Br = styled.br`
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    display: none;
  }
`

export const Message = () => {
  return (
    <Node>
      <Wrapper>
        <SpWrapper>
          <FadeIn {...FadeInOptions}>
            <SubTitle subTitle="message" text="メッセージ" color="#fff" />
            <CopyTitle>ゼロをイチへ。人々の日常に革新を。</CopyTitle>
            <TextWrapper>
              <Text>日常に存在するあたりまえは、すべて誰かの挑戦から始まっている。</Text>
              <Text>そのイノベーションには、どんな想いがあり、どんなストーリーがあったのか。<Br />そこにある創り手としての別世界に気づかされる。</Text>
              <Text>未知への好奇心、誰かを喜ばせたいという純粋な気持ち、強い使命感、大きな不安。<Br />成功に至るまでの創り手の情熱と挑戦の歴史が刻まれている。</Text>
              <Text>あなたにとって、人生の価値とは何だろう。<Br />手に入れたものはいつか手放さなければならない。<Br />きっと大事なのは、何かを得るより、与えること、残すこと。</Text>
              <Text>「自分はこの世にいったい何を残すことができるのか」<Br />創り手として、そこに答えを見出したい。</Text>
              <Text>自分が創ったものが、未来の誰かを支えるものになっている。<Br />誰かの挑戦を後押しする武器となったり、人の和が生まれるきっかけになっているかもしれない。<Br />その裏ストーリーにあるのは、自分という革新者が刻んだひとつの偉業。</Text>
              <Text>人生は短い。決断を迷っているうちに終わってしまう。<Br />他者との比較はやめ、自分軸で生きよう。<Br />人生を賭けても良いと思えるほどのやりがいのあることに没頭しよう。<Br />本当は心配するようなリスクなんて無い。<Br />新たな挑戦は確実に自分の価値を高め、可能性を拡げ、チャンスを引き寄せる。</Text>
              <Text>かけがえない人生の時間を価値あるものにするには、<Br />自分時間を生き、楽しむセンスを持たなければならない。</Text>
              <Text>ここには仕事を楽しむカルチャーがあり、共に戦う同志がいる。<Br />未知なる冒険はハイリスクだけど、先駆者だけが味わえる絶景がある。<Br />本気の挑戦は、理屈抜きに響いてくる感動がある。</Text>
              <Text>ネクストライブの創造は、世の中の挑戦を支えるインフラになる。<Br />時を超え、未来永劫にわたって人を育み、社会の成長に貢献する事業を残したい。</Text>
              <Text>今の常識は非常識になる時がくる。<Br />変化という進化を楽しみながら、この世界に自分たちが生きた証を刻み、自分の物語を次世代へ残そう。</Text>
              <Text>強い意志を持って。</Text>
            </TextWrapper>
          </FadeIn>
        </SpWrapper>
      </Wrapper>
    </Node>
  )
}

export default Message
