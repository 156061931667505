import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst";

const Text = styled.h3`
  margin: 0;
  padding: 0;

  color: ${props => props.color};
  text-align: center;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 84px;
  letter-spacing: 2.8px;
  text-transform: capitalize;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 40px;
    line-height: 60px;
    letter-spacing: 2px;
  }
  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 1.6px;
  }
`

type Props = {
  text: string,
} & {
  color?: string,
}

export const CatchCopy: React.FC<Props> = (props) => {
  return (
    <Text color={props.color}>{props.text}</Text>
  )
}

CatchCopy.defaultProps = {
  color: '#222',
}
